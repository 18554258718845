import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MyAPI from "./API";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const [data, setData] = useState(null);
  const [lang, setLang] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const language = window.location.pathname.split("/")[1];
    setLang(language);
  }, []);

  return (
    <>
      <MyAPI endpoint={"footer"} setData={setData} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "start", md: "center" },
          gap: { xs: "2em", md: "4em" },
          p: { xs: "1em", md: "2em", lg: "4em" },
          flexDirection: { xs: "column", sm: "row" },
          maxWidth: "100%",
          bgcolor: "#E9E9E9",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: { xs: "1em", md: "2em", lg: "3em" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {data?.footer?.socials?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "1em",
                  cursor: "pointer",
                  "&:hover > img": {
                    transition: "0.3s all",
                    transform: "scale(1.1)",
                  },
                  "&:hover > :last-child": {
                    transition: "0.3s all",
                    color: "#67398D",
                  },
                }}
                onClick={() => window.open(item.url, "_blank")}
              >
                <img src={item.image} alt="" />
                <Typography
                  sx={{
                    fontFamily: "CairoMedium",
                    fontSize: {
                      xs: "20px",
                      md: "24px",
                      xl: "28px",
                    },
                    color: "#000000",
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              "& .MuiTypography-root": {
                fontFamily: "MeriendaRegular",
                fontSize: {
                  xs: "16px",
                  md: "20px",
                },
                color: "#706F71",
                textTransform: "none",
              },
            }}
          >
            {data?.footer?.contacts?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                  alignItems: "start",
                }}
              >
                {item.email && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      const mailToLink = `mailto:${item.email}`;
                      const mailSubject = encodeURIComponent(
                        "Website @Shutter Studio"
                      );

                      window.location.href = `${mailToLink}?subject=${mailSubject}`;
                    }}
                  >
                    <img
                      src="/images/mail.png"
                      alt=""
                      style={{ width: "30px" }}
                    />
                    <Typography>{item.email}</Typography>
                  </Box>
                )}

                {item.phone && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "1em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(`https://wa.me/${item.phone}`);
                    }}
                  >
                    <img
                      src="/images/phone.png"
                      alt=""
                      style={{ width: "30px" }}
                    />
                    <Typography>{item.phone}</Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: lang === "en" ? "start" : "end",
            gap: "2em",
            "& > .MuiBox-root": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: lang === "en" ? "row" : "row-reverse",
            },
          }}
        >
          {data?.footer?.addresses?.map((item, index) => (
            <Box
              key={index}
              sx={{
                gap: "2em",
                cursor: "pointer",
              }}
              onClick={() => {
                const googleMapsUrl = item.url;

                window.open(googleMapsUrl, "_blank");
              }}
            >
              <img src={item.image} alt="" style={{ maxWidth: "80px" }} />

              <Box>
                <Typography
                  sx={{
                    color: "#67398D",
                    fontFamily: "CairoBold",
                    fontSize: { xs: "20px", md: "28px" },
                    direction: lang === "en" ? "ltr" : "rtl",
                  }}
                >
                  {
                    item.translations?.find(
                      (item) => item.fieldName === "title"
                    )?.translatedValue
                  }
                </Typography>
                <Typography
                  sx={{
                    color: "#636363",
                    fontSize: { xs: "16px", md: "20px" },
                    direction: lang === "en" ? "ltr" : "rtl",
                  }}
                >
                  {
                    item.translations?.find(
                      (item) => item.fieldName === "address"
                    )?.translatedValue
                  }
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          background: "linear-gradient(to right, #67398D, #A34D97)",
          display: "flex",
          justifyContent: "center",
          padding: "1em",
          maxWidth: "100%",
          pointerEvents: "none",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#FFFFFF",
            fontFamily: "CairoSemiBold",
            direction: lang === "en" ? "ltr" : "rtl",
          }}
        >
          {t("All right reserved to PentaValue© 2022")}
        </Typography>
      </Box>
    </>
  );
}
