import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import MyAPI from "../../components/API";
import Loader from "./../../components/Loader";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function Addons() {
  const [data, setData] = useState(null);
  const { lang } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <MyAPI endpoint={"addons"} setData={setData} />
      <Box sx={{ position: "relative", width: "100%" }}>
        <img
          src="/images/addonsBanner.png"
          alt=""
          style={{
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
            maxHeight: "400px",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            bottom: "0",
            right: "0",
            color: "white",
            padding: "0.5em",
            fontSize: { xs: "28px", sm: "38px", md: "52px", lg: "64px" },
            fontFamily: "CairoBold",
          }}
        >
          {t("addons")}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: "#D9D9D9",
          padding: "2em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data ? (
          <Typography
            variant="body1"
            sx={{
              background:
                "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              width: "70%",
              fontFamily: "CairoSemiBold",
              fontSize: {
                xs: "20px",
                sm: "22px",
                md: "24px",
                lg: "28px",
                xl: "32px",
              },
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.topBanner?.map(
              (item) => item.translations[0]?.translatedValue
            )}
          </Typography>
        ) : (
          <Loader />
        )}
      </Box>
      {data ? (
        <Grid
          container
          spacing={8}
          sx={{
            padding: { xs: "2em 0", sm: "4em" },
          }}
        >
          {data?.addons?.map((addon, index) => (
            <Grid item xs={12} key={index} sx={{ maxWidth: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: [index % 2 === 0 ? "row" : "row-reverse"],
                  },
                  gap: "4em",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{ flex: 1, display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={addon.image}
                    alt=""
                    style={{ maxHeight: "60vh", maxWidth: "100%" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      background:
                        "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textAlign:
                        lang === "en"
                          ? { xs: "center", sm: "start" }
                          : { xs: "center", sm: "end" },
                      fontFamily: "CairoBold",
                      fontSize: {
                        xs: "28px",
                        sm: "32px",
                        md: "36px",
                        lg: "42px",
                        xl: "48px",
                      },
                    }}
                  >
                    {
                      addon.translations?.find(
                        (item) => item.fieldName === "title"
                      )?.translatedValue
                    }
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#636363",
                      textAlign:
                        lang === "en"
                          ? { xs: "center", sm: "start" }
                          : { xs: "center", sm: "end" },
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "20px",
                        lg: "22px",
                        xl: "24px",
                      },
                    }}
                  >
                    {
                      addon.translations?.find(
                        (item) => item.fieldName === "description"
                      )?.translatedValue
                    }
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      component={Link}
                      to={`/${lang}/whatisnew`}
                      sx={{
                        background:
                          "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
                        color: "white",
                        borderRadius: "10px",
                        width: {
                          xs: "80%",
                          sm: "70%",
                          md: "60%",
                          lg: "50%",
                          xl: "40%",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "CairoMedium",
                          fontSize: {
                            xs: "20px",
                            md: "24px",
                          },
                          whiteSpace: "nowrap",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {t("see it in action")}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Loader />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(to right, #490081 0%, #200055 100%)",
          padding: "2em",
          gap: "1em",
          mb: "4em",
        }}
      >
        {data ? (
          <Typography
            variant="h4"
            sx={{
              background:
                "linear-gradient(to right, #CD51CA 0%, #5360E2 100%, #3065E9 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontFamily: "CairoBold",
              fontSize: {
                xs: "36px",
                md: "44px",
                xl: "48px",
              },
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.whatIsNew?.map(
              (item) => item.translations[0]?.translatedValue
            )}
          </Typography>
        ) : (
          <Loader />
        )}
        {data ? (
          <Typography
            variant={"body1"}
            sx={{
              color: "white",
              textAlign: "center",
              width: "70%",
              fontFamily: "CairoSemiBold",
              fontSize: {
                xs: "20px",
                md: "26px",
              },
              direction: lang === "en" ? "ltr" : "rtl",
            }}
          >
            {data?.staticPages?.whatIsNew?.map(
              (item) => item.translations[1]?.translatedValue
            )}
          </Typography>
        ) : (
          <Loader />
        )}
        <Button
          sx={{
            color: "black",
            background: "linear-gradient(to right, #CDDDFE 0%, #F0CEF3 100%)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "CairoMedium",
              fontSize: {
                xs: "16px",
                md: "20px",
              },
            }}
          >
            {t("check it out")}
          </Typography>
        </Button>
      </Box>
    </>
  );
}
