import {
  Alert,
  Box,
  Button,
  Fade,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Slide,
  Snackbar,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import QuotationModal from "../../../components/QuotationModal";

const MaterialUISwitch = styled(Switch)(({ checked }) => ({
  width: 62,
  height: 35,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 0,
    padding: 0,
    transform: "translateX(6px)",
    top: 7,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(34px)",
      "& .MuiSwitch-thumb:before": {},
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#FFFFFF",
        border: "1px solid #A34D97",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#A34D97",
    backgroundImage: `url(/images/arrow.svg)`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    transform: checked ? "rotate(0)" : "rotate(180deg)",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: "1px solid #A34D97",
  },
}));

export default function Photobooth360() {
  const [data, setData] = useState(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const [switchChecked, setSwitchChecked] = useState(true);
  const [openModal, setOpenModal] = useState(null);

  const { lang } = useParams();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/services/${id}`, {
        params: {
          language: lang,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, []);

  function getYouTubeVideoId(url) {
    const videoIdPattern =
      /(?:\?v=|\/embed\/|\/watch\?v=|\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(videoIdPattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };
  return (
    <>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              justifyContent: "center",
              alignItems: "center",
              p: "2em",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "#67398D",
                textAlign: "center",
                fontFamily: "CairoBold",
              }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "title"
                )?.translatedValue
              }
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "#A34D97", width: "70%", textAlign: "center" }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "description"
                )?.translatedValue
              }
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              maxWidth: "100%",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                bgcolor: "#67398D",
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "2em",
                gap: "1em",
                flex: 1,
                maxWidth: "100%",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textAlign: "center", fontFamily: "CairoBold" }}
              >
                {
                  data?.service?.translations.find(
                    (item) => item.fieldName === "title"
                  )?.translatedValue
                }
              </Typography>
              <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                {
                  data?.service?.translations.find(
                    (item) => item.fieldName === "description"
                  )?.translatedValue
                }
              </Typography>
              <Button
                sx={{
                  bgcolor: "#FFFFFF",
                  outline: "1px solid #FAB50C",
                  color: "#FAB50C",
                  borderRadius: "10px",
                  transition: "0.5s all",
                  boxShadow:
                    "0px 4px 4px rgba(103, 57, 141, 0.7), 0px 4px 4px rgba(250, 181, 12, 0.5)",
                  "&: hover": {
                    bgcolor: "#FAB50C",
                    color: "#FFFFFF",
                  },
                }}
                onClick={() => handleOpenModal("quotation")}
              >
                <Typography>{t("reserve now")}</Typography>
              </Button>
            </Box>
            <Box sx={{ display: "flex", flex: 1, width: "100%" }}>
              <img
                src={data?.service?.image ? data.service.image : <Loader />}
                alt=""
                style={{
                  maxHeight: "60vh",
                  maxWidth: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              p: { xs: "1em", sm: "2em", lg: "4em" },
              display: "flex",
              flexDirection: "column",
              gap: "2em",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#A34D97",
                fontFamily: "CairoBold",
                direction: lang === "en" ? "ltr" : "rtl",
              }}
            >
              {
                data?.service?.translations.find(
                  (item) => item.fieldName === "featuresTitle"
                )?.translatedValue
              }
            </Typography>
            {data.service.translations.some(
              (item) =>
                item.fieldName === "features" &&
                item.translatedValue !== null &&
                item.translatedValue.length > 0
            ) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  backgroundImage: {
                    xs: "none",
                    sm: `url(${
                      data?.service?.featuresImage ? (
                        data.service.featuresImage
                      ) : (
                        <Loader />
                      )
                    })`,
                  },
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: lang === "en" ? "right" : "left",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: "2em",
                  direction: lang === "en" ? "ltr" : "rtl",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={
                      data?.service?.featuresImage ? (
                        data.service.featuresImage
                      ) : (
                        <Loader />
                      )
                    }
                    alt=""
                    style={{ width: "50%" }}
                  />
                </Box>

                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2em",
                    direction: lang === "en" ? "ltr" : "rtl",
                  }}
                >
                  {data.service.translations
                    .filter((item) => item.fieldName === "features")
                    .map((item, index) => (
                      <ListItem
                        disablePadding
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1em",
                        }}
                      >
                        <Box
                          sx={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            bgcolor: "#A34D97",
                          }}
                        />
                        <Typography
                          variant="h5"
                          sx={{ fontFamily: "CairoBold" }}
                        >
                          {item?.translatedValue ? (
                            item?.translatedValue
                          ) : (
                            <Loader />
                          )}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: { xs: "1em", sm: "2em", lg: "4em" },
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: "#67398D", fontFamily: "CairoBold" }}
            >
              {t("activity")}
            </Typography>
            <Box
              sx={{
                padding: "4em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1em",
                  alignItems: "center",
                  "> .MuiTypography-root": {
                    fontFamily: "CairoBold",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    transition: "0.3s all",
                    color: switchChecked ? "#000000" : "#A34D97",
                  }}
                >
                  {t("videos")}
                </Typography>
                <MaterialUISwitch
                  defaultChecked
                  checked={switchChecked}
                  onChange={(e) => setSwitchChecked(e.target.checked)}
                />
                <Typography
                  variant="h6"
                  sx={{
                    transition: "0.3s all",
                    color: switchChecked ? "#A34D97" : "#000000",
                  }}
                >
                  {t("photos")}
                </Typography>
              </Box>
              {switchChecked ? (
                data?.service?.activityImages.length > 0 ? (
                  <ImageList variant="masonry" cols={3} gap={8}>
                    {data?.service?.activityImages?.map((photo, index) => (
                      <ImageListItem key={index}>
                        {isLoadingImages && <Loader />}
                        <img
                          src={photo.url}
                          alt={`Gallery ${index}`}
                          style={{
                            display: isLoadingImages ? "none" : "block",
                          }}
                          onLoad={() => setIsLoadingImages(false)}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: 600, p: "2em" }}>
                    No Data To Display
                  </Typography>
                )
              ) : data?.service?.activityVideos.length > 0 ? (
                <Grid container spacing={3}>
                  {data?.service?.activityVideos?.map((video, index) => {
                    const isYouTubeVideo = video.url.includes("youtube.com");

                    return (
                      <Grid item xs={12} md={4} key={index}>
                        {isLoadingVideos && <Loader />}
                        {isYouTubeVideo ? (
                          <YouTube
                            videoId={getYouTubeVideoId(video.url)}
                            opts={{
                              width: "100%",
                            }}
                            style={{
                              display: isLoadingVideos ? "none" : "block",
                            }}
                            onReady={() => setIsLoadingVideos(false)}
                          />
                        ) : (
                          <video
                            src={video.url}
                            controls
                            style={{
                              width: "100%",
                              display: isLoadingVideos ? "none" : "block",
                            }}
                            onLoad={() => setIsLoadingVideos(false)}
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Typography variant="h6" sx={{ fontWeight: 600, p: "2em" }}>
                  No Data To Display
                </Typography>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Loader />
      )}
      {openModal === "quotation" && (
        <QuotationModal
          open={true}
          handleClose={handleCloseModal}
          onSuccess={handleSnackBarOpen(SlideTransition, false)}
          onError={handleSnackBarOpen(SlideTransition, true)}
        />
      )}
      <Snackbar
        open={snackBar.open}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
        TransitionComponent={snackBar.Transition}
        key={snackBar.Transition.name}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={snackBar.isError ? "error" : "success"}
          sx={{
            width: "100%",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {snackBar.isError
            ? "We were unable to submit your form!"
            : "Form Submitted Successfully!"}
        </Alert>
      </Snackbar>
    </>
  );
}
