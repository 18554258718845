import Title from "./components/TopBanner";
import Services from "./components/Services";
import WhyShutter from "./components/BottomBanner";
import Partners from "./components/Partners";
import MyAPI from "../../components/API";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function Home() {
  const [data, setData] = useState(null);
  const { lang } = useParams();
  return (
    <>
      <MyAPI endpoint={"homepage"} setData={setData} />
      <Title data={data?.staticPages?.title} lang={lang} />
      <Services lang={lang} />
      <WhyShutter data={data?.staticPages?.whyShutter} lang={lang} />
      <Partners data={data?.sliders?.partners} />
    </>
  );
}
