import React, { useState, useRef, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import Slider from "react-slick";
import { ArrowRightAlt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function Services({ lang }) {
  const [sliderSettings, setSliderSettings] = useState({});
  const [data, setData] = useState(null);

  const { t } = useTranslation();
  const sliderRef = useRef();

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    setSliderSettings({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipe: true,
      swipeToSlide: true,
      arrows: false,
      autoplay: true,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      let slidesToShow = 3;

      if (window.innerWidth < 960) {
        slidesToShow = 2;
      }

      if (window.innerWidth < 600) {
        slidesToShow = 1;
      }

      setSliderSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow,
      }));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const backgrounds = [
    "images/DarkPurple.png",
    "images/MidPurple.png",
    "images/Pink.png",
  ];

  useEffect(() => {
    const language = window.location.pathname.split("/")[1] || "en";

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/services?key=!isDeleted&language=${language}`
      )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "2em",
        padding: "3em",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "2em", sm: "0" },
          direction: lang === "en" ? "ltr" : "rtl",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontFamily: "CairoBold",
            fontSize: { xs: "32px", sm: "36px", md: "42px", lg: "48px" },
            textAlign: { xs: "center", sm: "start" },
          }}
        >
          {t("About our digital activition")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            "> button": {
              width: "50px",
              height: "50px",
              bgcolor: "#D9D9D9",
              "& svg.MuiSvgIcon-root": {
                width: "100%",
              },
            },
            flexDirection: lang === "en" ? "row" : "row-reverse",
          }}
        >
          <IconButton onClick={previousSlide}>
            <ArrowRightAlt sx={{ transform: "rotate(180deg)" }} />
          </IconButton>
          <IconButton onClick={nextSlide}>
            <ArrowRightAlt />
          </IconButton>
        </Box>
      </Box>
      {data?.services ? (
        <Slider ref={sliderRef} {...sliderSettings}>
          {data?.services?.map((service, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
              }}
            >
              <Box
                component={Link}
                to={`services/${service.id}`}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage:
                    index !== service?.length - 1
                      ? `url(${backgrounds[index % backgrounds.length]})`
                      : "none",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  p: "0.5em",
                  borderRadius: "10px",
                  cursor: "pointer",
                  transition: "0.3s all",
                  "&:hover": {
                    transform: "scale(0.95)",
                  },
                }}
              >
                <img
                  src={service.image}
                  alt={`Slide ${index}`}
                  style={{ height: "300px" }}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  component={Link}
                  to={`services/${service.id}`}
                  variant="caption"
                  sx={{ margin: "0 auto", fontSize: "24px" }}
                >
                  {
                    service.translations.find(
                      (item) => item.fieldName === "title"
                    )?.translatedValue
                  }
                </Button>
              </Box>
            </Box>
          ))}
        </Slider>
      ) : (
        <Loader />
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          component={Link}
          to={`services`}
          sx={{
            width: "fit-content",
            border: "1px solid #706F71",
            color: "#706F71",
            fontSize: { xs: "16px", md: "20px" },
            "&: hover": {
              border: "1px solid #67398D",
              color: "#67398D",
            },
          }}
        >
          {t("see all")}
        </Button>
      </Box>
    </Box>
  );
}
