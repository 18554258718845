import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import QuotationModal from "./QuotationModal";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function Header() {
  const [openModal, setOpenModal] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const selectedLanguage = useLocation().pathname.split("/")[1];

  const open = Boolean(anchorEl);
  const location = useLocation();
  const { t } = useTranslation();

  const handleLanguageChange = (lang) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        localStorage.setItem("lang", lang);
        const currentURL = window.location.pathname;
        const languageSegment = lang === "ar" ? "en" : "ar";
        const newURL = currentURL.replace(`/${languageSegment}`, `/${lang}`);
        window.location.href = newURL;
      })
      .catch((error) => {
        console.error("Error occurred while changing language:", error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (modalName) => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }

  const [snackBar, setSnackBar] = useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarOpen = (Transition, isError) => () => {
    setSnackBar({
      open: true,
      Transition,
      isError,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBar({
      ...snackBar,
      open: false,
    });
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#FFFFFF",
          },
          scrollButtons: {
            "& .MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  });

  const navItems = [
    { name: t("home"), link: `/${selectedLanguage}` },
    { name: t("services"), link: `/${selectedLanguage}/services` },
    { name: t("projects"), link: `/${selectedLanguage}/projects` },
    { name: t("addons"), link: `/${selectedLanguage}/addons` },
    { name: t("what’s new"), link: `/${selectedLanguage}/whatisnew` },
  ];

  useEffect(() => {
    const selectedIndex = navItems.findIndex(
      (item) => item.link === location.pathname
    );

    if (selectedIndex !== -1) {
      setValue(selectedIndex);
    }
  }, [location]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#67398D",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: "0",
          zIndex: "1",
          gap: "1em",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "120px",
            height: "60px",
            padding: "1em",
            position: "relative",
            borderTopRightRadius: "7px",
            borderBottomRightRadius: "7px",
          }}
        >
          <img
            src="/images/shutterLogo.png"
            alt=""
            style={{
              width: "100%",
              zIndex: "1",
            }}
          />
          <span
            style={{
              position: "absolute",
              top: "0px",
              right: "5px",
              bottom: "0px",
              width: "20px",
              background: "#FFFFFF",
              transform: "translate(50%, 0)",
              borderRadius: "100%",
              pointerEvents: "none",
              zIndex: "0",
            }}
          ></span>
        </Box>
        <ThemeProvider theme={theme}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            sx={{
              "& .MuiTabs-scrollableX .MuiTabs-flexContainer": {
                gap: { xs: "0.5em", md: "1em", xl: "2em" },
              },
              display: { xs: "none", sm: "flex" },
              direction: selectedLanguage === "en" ? "ltr" : "rtl",
            }}
          >
            {navItems?.map((item, index) => (
              <Tab
                key={index}
                component={Link}
                to={item.link}
                label={
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "24px",
                      },
                      color: "#FFFFFF",
                      textDecoration: "none",
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </ThemeProvider>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, display: { xs: "block", sm: "none" } }}
          aria-controls={open ? "header-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MenuIcon sx={{ width: 32, height: 32, color: "#FFFFFF" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="header-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {navItems?.map((item, index) => (
            <MenuItem key={index} component={Link} to={item.link}>
              {item.name}
            </MenuItem>
          ))}
          <Divider />
          <Box
            sx={{
              padding: { xs: "0 0.5em", sm: "0 1em" },
              "& Button": {
                color: "#000",
                width: "fit-content",
                height: "fit-content",
                fontFamily: "CairoMedium",
                fontSize: { sm: "12px", lg: "14px", xl: "16px" },
              },
              "& img": {
                width: "25px",
                height: "25px",
                marginRight: "5px",
              },
            }}
          >
            {selectedLanguage === "ar" ? (
              <Button onClick={() => handleLanguageChange("en")}>
                <img src="/images/usaFlag.png" alt="" />
                English
              </Button>
            ) : (
              <Button onClick={() => handleLanguageChange("ar")}>
                <img src="/images/saudiFlag.png" alt="" />
                عربي
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "0.5em",
              padding: { xs: "0 0.5em", sm: "0 1em" },
              "& Button": {
                color: "#67398D",
                backgroundColor: "white",
                borderRadius: "10px",
                width: "100%",
                height: "fit-content",
                whiteSpace: "nowrap",
                fontFamily: "CairoMedium",
                boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.4)",
                "&:hover": {
                  bgcolor: "#FFFFFF",
                  boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.4)",
                },
              },
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleOpenModal("quotation")}
            >
              {t("Get Quotation")}
            </Button>
          </Box>
        </Menu>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: "0.5em",
            padding: { xs: "0 0.5em", sm: "0 1em" },
          }}
        >
          <Box
            sx={{
              "& Button": {
                color: "#FFF",
                width: "fit-content",
                height: "fit-content",
                fontFamily: "CairoMedium",
                fontSize: { sm: "12px", lg: "14px", xl: "16px" },
              },
              "& img": {
                width: "25px",
                height: "25px",
                marginRight: "5px",
              },
            }}
          >
            {selectedLanguage === "ar" ? (
              <Button onClick={() => handleLanguageChange("en")}>
                <img src="/images/usaFlag.png" alt="" />
                English
              </Button>
            ) : (
              <Button onClick={() => handleLanguageChange("ar")}>
                <img src="/images/saudiFlag.png" alt="" />
                عربي
              </Button>
            )}
          </Box>

          <Button
            variant="contained"
            onClick={() => handleOpenModal("quotation")}
            sx={{
              color: "#67398D",
              backgroundColor: "white",
              borderRadius: "10px",
              whiteSpace: "nowrap",
              fontFamily: "CairoMedium",
              fontSize: { sm: "12px", lg: "14px", xl: "16px" },
              boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.4)",
              "&:hover": {
                bgcolor: "#FFFFFF",
                boxShadow: "0px 4px 4px 0px rgba(255, 255, 255, 0.4)",
              },
            }}
          >
            {t("Get Quotation")}
          </Button>
        </Box>
        {openModal === "quotation" && (
          <QuotationModal
            open={true}
            handleClose={handleCloseModal}
            onSuccess={handleSnackBarOpen(SlideTransition, false)}
            onError={handleSnackBarOpen(SlideTransition, true)}
          />
        )}
        <Snackbar
          open={snackBar.open}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
          TransitionComponent={snackBar.Transition}
          key={snackBar.Transition.name}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity={snackBar.isError ? "error" : "success"}
            sx={{
              width: "100%",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {snackBar.isError
              ? "We were unable to submit your form!"
              : "Form Submitted Successfully!"}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default Header;
