import { Box, IconButton, Typography } from "@mui/material";
import Loader from "../../../components/Loader";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";
import { ArrowRightAlt } from "@mui/icons-material";

function Title({ data, lang }) {
  const [sliderSettings, setSliderSettings] = useState({});
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [seekToStart, setSeekToStart] = useState(false);

  const sliderRef = useRef();

  function getYouTubeVideoId(url) {
    const videoIdPattern =
      /(?:\?v=|\/embed\/|\/watch\?v=|\/shorts\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(videoIdPattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }

  useEffect(() => {
    setSliderSettings({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      swipeToSlide: false,
      arrows: false,
      autoplay: false,
    });

    const firstYoutubeItem = data?.find((item) =>
      item.image.includes("youtube.com")
    );

    if (firstYoutubeItem) {
      setCurrentVideoId(getYouTubeVideoId(firstYoutubeItem.image));
    }
  }, [data]);

  const videoOptions = {
    height: "800px",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      disablekb: 1,
      mute: 1,
      loop: 1,
    },
  };

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const onStateChange = (event) => {
    if (data?.length > 1) {
      if (event.data === YouTube.PlayerState.ENDED) {
        nextSlide();
      }

      if (event.data === YouTube.PlayerState.PLAYING && seekToStart) {
        event.target.seekTo(0);
        setSeekToStart(false);
      }
    } else {
      if (event.data === YouTube.PlayerState.ENDED) {
        event.target.playVideo();
      }
    }
  };

  const handleSlide = (slideIndex) => {
    setSeekToStart(true);
    const currentItem = data[slideIndex];

    if (currentItem.image.includes("images")) {
      setTimeout(() => {
        nextSlide();
      }, 3000);
    } else {
      const newVideoId = getYouTubeVideoId(currentItem?.image);
      setCurrentVideoId(newVideoId);
    }
  };

  return (
    <>
      {data ? (
        <>
          <Slider ref={sliderRef} {...sliderSettings} afterChange={handleSlide}>
            {data?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "1em",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: item?.image.includes("images")
                      ? `url(${item?.image})`
                      : "",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    transition: "0.3s all",
                    // "&:hover": {
                    //   transform: "scale(0.95)",
                    // },
                    minHeight: "75vh",
                    position: "relative",
                  }}
                >
                  {item?.image.includes("youtube.com") ? (
                    <Box
                      sx={{
                        width: "100%",
                        position: "relative",
                        flexDirection: lang === "en" ? "row" : "row-reverse",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      {/* <IconButton onClick={previousSlide}>
                        <ArrowRightAlt sx={{ transform: "rotate(180deg)" }} />
                      </IconButton> */}

                      <Box sx={{ width: "100%" }}>
                        <YouTube
                          videoId={currentVideoId}
                          opts={videoOptions}
                          onStateChange={onStateChange}
                        />
                      </Box>

                      {/* <IconButton onClick={nextSlide}>
                        <ArrowRightAlt />
                      </IconButton> */}
                    </Box>
                  ) : item?.image.includes("images") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4em",
                        padding: "5em",
                        alignItems: lang === "en" ? "start" : "end",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "6em",
                          backgroundImage:
                            "linear-gradient(to bottom, #67398D, #D39ED5)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {item?.translations?.map((item, index) => (
                          <Typography
                            key={index}
                            variant="h1"
                            sx={{
                              fontSize: {
                                xs: "38px",
                                sm: "42px",
                                md: "48px",
                                lg: "58px",
                                xl: "64px",
                              },
                              fontFamily: "CairoBold",
                              textTransform: "uppercase",
                              whiteSpace: "pre-wrap",
                              lineHeight: "2em",
                              direction: lang === "en" ? "ltr" : "rtl",
                            }}
                          >
                            {item?.translatedValue.split(" ").join("\n")}
                          </Typography>
                        ))}
                      </Box>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: {
                            xs: "20px",
                            sm: "24px",
                            md: "32px",
                            lg: "36px",
                          },
                          color: "white",
                          direction: lang === "en" ? "ltr" : "rtl",
                        }}
                      >
                        {data?.map((item) => item?.translatedValue)}
                      </Typography>
                    </Box>
                  ) : (
                    item?.image
                  )}

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      cursor: "default",
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default Title;
